/*----------------------------------------*/
/*  01. VARIBALES DECLARARION
/*----------------------------------------*/
:root {
  // Colors
  --primary: #d8ff36;
  --secondary: #1e1e1e;

  --white: #fff;
  --white-2: #e3e3e3;

  --black: #000;
  --black-2: #252525;
  --black-3: #1f242c;
  --black-4: #67687a;
  --black-5: #2b2b5e;

  --gray: #616161;
  --gray-2: #f4f5fa;
  --gray-3: #333f4d;
  --gray-4: #82828a;
  --gray-5: #3c4958;
  --gray-6: #757474;

  --blue: #0058aa;
  --blue-2: #064bb5;

  --yellow: #b8fb5e;
  --yellow-2: #d8ff36;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  overflow-x: hidden;
}

// Google Fonts
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Font Family
$font_barlow: 'Montserrat', sans-serif;;

h1,h2,h3,h4,h5,h6 {
  font-family: 'Barlow', sans-serif!important;
}

// Responsive Variable
$xxxl: "(min-width: 1920px)";
$xxl: "(max-width: 1919px)";
$xl: "(max-width: 1399px)";
$lg: "(max-width: 1199px)";
$md: "(max-width: 991px)";
$sm: "(max-width: 767px)";
$xs: "(max-width: 574px)";

::-webkit-scrollbar {
  width: 8px;
  background-color: var(--secondary);
}

::-webkit-scrollbar-track {
  background-color: var(--secondary);
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: var(--primary);
}
