.hero__slider {
  background-position: 50% 28%!important;
  background-size: cover!important;
  background-repeat: no-repeat!important;
  width: 100vw!important;
  height: 70vh!important;
  position: relative;
  z-index: 0;
}
.modal-header {
  color: white;
  border: none;
}
.modal-header button {
  display: none;
}
.modal-footer {
  border-top: none;
}
.modal-footer .btn-primary {
  background: #d8ff36;
  color: black;
  border: none;
}
.modal-footer .btn-primary:focus {
  opacity: .6;
  background: #d8ff36;
}
button[disabled], button:disabled {
  cursor: not-allowed;
}
.modal-dialog {
  background: transparent;
}
.modal-content {
  background: #252525;
  /* border: 2px solid #d8ff36; */
  border: none;
  box-shadow: none;
  overflow: hidden;
  border-radius: 12px;
}
.form_modal_funeral input {
  width: 100%;
  background: #2d2d2d;
  border: none;
  color: #fff;
  padding: 0.7rem 1rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  outline: none;
  transition: 1s;
}
.form_modal_funeral input:focus {
  background: black;
}
@media (max-width:600px) {
  .hero__slider {
    height: 65vh!important;
  }
  .funeral__hero {
    height: 75vh!important;
  }
  .hero__slider_ {
    background: url(../public/assets/imgs/home_mobile_1.png)!important;
    background-size: cover!important;
  }
  
  .hero__slider_2 {
    background: url(../public/assets/imgs/home_mobile_2.png)!important;
    background-size: cover!important;
  }
  
  .hero__slider_3 {
    background: url(../public/assets/imgs/home_mobile_3.png)!important;
    background-size: cover!important;
  }
}

.hero__slider_ {
  background: url(../public/assets/imgs/home_1.png);
}

.hero__slider_2 {
  background: url(../public/assets/imgs/home_2.png);
}

.hero__slider_3 {
  background: url(../public/assets/imgs/home_3.png);
}

.hero__slider_cover::after {
  content: '';
  position: absolute;
  inset: 0 0 0 0;
  background: black;
  opacity: .7;
  z-index: 1;
}

.viagens__hero {
  background-image: url(../public/assets/imgs/hero_viagens.jpg);
  height: 80vh!important;
}
.saude__hero {
  background-image: url(../public/assets/imgs/hero_saude.jpg);
}

@media (max-width:600px) {
  .hero__slider_saude {
    height: 100vh!important;
  }
  .hero__slider_saude_ {
    background: url(../public/assets/imgs/usosaude_banner_mobile1.png)!important;
    background-size: cover!important;
  }
  
  .hero__slider_saude_2 {
    background: url(../public/assets/imgs/usosaude_banner_mobile2.png)!important;
    background-size: cover!important;
  }
  .hero__two_columns_icons {
    grid-template-columns: 1fr!important;
  }
  .newsletter__form-wrapper-5 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .newsletter__form-wrapper-5 a:first-child {
    width: 230px;
    height: auto!important;
  }
  .newsletter__form-wrapper-5 a:nth-child(2) {
    height: auto;
  }
  .astaton-section-wrapper-5 {
    margin-bottom: 10px;
  }
  .mobile_arrow {
    margin-top: 10px!important;
  }
  .mobile_arrow_mobile {
    margin-top: 0px!important;
  }
  .mobile_arrow_mobile .cursor {
    background: #000;
  }
  .mobile_arrow_mobile .cursor:hover {
    background: var(--primary);
  }
  .team__area-5 .team-active-5 .swiper-wrapper {
    padding-bottom: 10px;
  }
  .testimonial-five-active .swiper-wrapper {
    padding-bottom: 10px;
  }
}
.text__destaq {
  background: var(--primary);
  color: var(--secondary);
}
@media (min-width:600px) {
  .mobile_arrow_mobile {
    display: none!important;
  }
}
.destaq__gray__text {
  background-color: #000;
  width: fit-content;
  padding: 10px 20px;
  border-radius: 4px;
  color: #fff;
}
.hero__slider_saude_ {
  background: url(../public/assets/imgs/usosaude_banner_1.png);
}

.hero__slider_saude_2 {
  background: url(../public/assets/imgs/usosaude_banner_2.png);
}


.saude__hero2 {
  /* background-image: url(../public/assets/imgs/hero_saude2.jpg); */
}
.pet__hero {
  background-image: url(../public/assets/imgs/hero_pet.jpg);
}
.funeral__hero {
  background-image: url(https://www.casaderepousoemfamilia.com.br/wp-content/uploads/2020/12/lazer-idosos.jpg);
}
.justify__text {
  text-align: justify!important;
}

.text-green {
  color: var(--primary)!important;
}

.fw__300 {
  font-weight: 300!important;
}

.fw__600 {
  font-weight: 600!important;
}

/* travel form styles */
.grid_3 {
  grid-template-columns: repeat(3,1fr)!important;
}
.travel__form {
  background: var(--secondary);
  border-radius: 8px;
  padding: 1rem 0.75rem;
  width: fit-content;

  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.travel__form_input {
  height: 45px;
  padding: 0 1rem;
  border: none;
  border-radius: 8px;
  color: var(--secondary);
  font-weight: 800;
  background: rgb(240, 240, 240);
}
.travel__form_input::placeholder {
  color: var(--secondary);
}
.travel__form_button {
  display:flex;
  align-items:center;
  background: #000;
  border-radius: 8px;
  color: var(--white);
  border: 0;
  height: 45px;
  font-size: 1.2em;
  padding: 0 1rem;
  transition: .6s;
}
.travel__form_button:hover {
  background: var(--primary);
  color: #000;
  opacity: 0.7;
}
@media (max-width: 1400px) {
  .mobile__text {
    font-size: 2.525rem;
  }
}
@media (max-width:600px) {
  .travel__form {
    flex-direction: column;
    width: 100%;
  }
  .travel__form input,button,select {
    width: 100%;
  }
  .hero__slider__viagens {
    height: 100vh!important;
  }
}
.text__link {
  position: relative;
}
.text__link::after {
  content: '↪';
  color: transparent;
  margin-left: 10px;
  transition: 0.8s;
}
.text__link::before {
  content: '';
  position: absolute;
  bottom: -1px;
  width: 0%;
  height: 2px;
  background: var(--primary);
  display: block;
  transition: 0.8s;
}
.text__link:hover::before {
  width: 35%;
}
.text__link:hover::after {
  color: var(--primary);
}

/* pet table */

.pet__table {
  background: #252525;
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
  margin: 3rem 0;
}

.pet__table th {
  background-color: #424242;
  padding: 1rem;
  text-align: left;
  font-weight: 600;
  color: #f2f2f2;
  font-size: 0.875rem;
  line-height: 1.6;
}

.pet__table td {
  background-color: rgb(17, 17, 17)242;
  border-top: 4px solid var(--secondary);
  border-right: 4px solid #1e1e1e;
  padding: 1rem;
  color: #f2f2f2;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.6;
}
.pet_promo_bg {
  background: url(https://www.estadao.com.br/recomenda/wp-content/uploads/2023/05/andrew-s-ouo1hbizWwo-unsplash.jpg);
}
.funeral_promo_bg {
  background: url(../public/assets/imgs/hero_funeral.jpg);
}
.default__promo {
  background-position: 20% 25%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 8rem 0;
  position: relative;
  z-index: 0;
}
.default__promo h3,p,button {
  position: relative;
  z-index: 2;
  text-align: start;
}
.default__promo button {
  background: var(--secondary);
  opacity: 0.7;
  color: var(--white);
  border: 0;
  border-radius: 8px;
  padding: 1rem 1.25rem;
  font-weight: 700;

  text-transform: uppercase;
  font-size: 1.15rem;

  transition: .8s;

  display: block;
}
.default__promo button:hover {
  opacity: 1;
  background: var(--primary);
  color: #000!important;
  cursor: pointer;
}
.default__promo::after {
  content: '';
    position: absolute;
    inset: 0 0 0 0;
    background: black;
    opacity: .7;
    z-index: 1;
}
.hero__two_columns_icons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}